import { useQuery } from '@tanstack/vue-query';
import { getUserPointHistory } from '../api';

export const createUserPointHistoryQuery = (uid?: string | null) => ({
  queryKey: ['users', uid, 'points'],
  queryFn: async () => {
    if (!uid) {
      return {
        data: [],
      };
    }

    return await getUserPointHistory(uid);
  },
  placeholderData: {
    data: [],
  },
  staleTime: 1000 * 60 * 60 * 1, // 1 hour
});

export function useUserPointHistoryQuery(uid?: string | null) {
  return useQuery(createUserPointHistoryQuery(uid));
}
