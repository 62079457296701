<script setup lang="ts">
import Joi from 'joi';
import IconToken from '@/assets/icons/token.svg?skipsvgo';
import type { PointTransactionType, PointsTransaction } from '@tn/shared';
import dayjs from 'dayjs';
import { useUserPointsHistory } from '~/features/points';
import {
  useAdjustTestClockPoints,
  useManageTestClockPoints,
} from '~/features/test-clock';

const notify = useNotifications();
const isOpen = ref(false);
defineEmits(['close']);

const { pointHistory, isLoading } = useUserPointsHistory();
const { updateTestClockPoints, isUpdating } = useManageTestClockPoints();
const { adjustTestClockPoints, isUpdating: isAdjusting } =
  useAdjustTestClockPoints();

const { memberUser } = useUserState();

const expand = ref({
  openedRows: [],
  row: {},
});

const rows = computed(() => {
  return pointHistory.value.map((item: PointsTransaction) => ({
    ...item,
  }));
});

const adjustedState = reactive({
  points: 0,
});

const addPointsState = reactive({
  points: 100,
});

const columns = [
  {
    key: 'createdAtTimeMs',
    label: 'Date',
  },
  {
    key: 'type',
    label: 'Type',
  },
  {
    key: 'points',
    label: 'Points',
  },
  {
    key: 'remainingPoints',
    label: 'Remaining',
  },
  {
    key: 'pointsExpiresAtTimeMs',
    label: 'Expires At',
  },
];

const adjustedSchema = Joi.object({
  points: Joi.number().min(-500).max(500).not(0).required(),
});

const addPointsSchema = Joi.object({
  points: Joi.number().min(1).max(500).required(),
});

async function onSubmitAdjustment(transactionId: string) {
  try {
    await adjustTestClockPoints({
      points: adjustedState.points,
      targetTransactionId: transactionId,
    });
  } catch (error: any) {
    notify.showErrorNotification({
      description: error?.statusMessage ?? 'Failed to adjust points',
    });
  }
}

const onSubmitAddPoints = async () => {
  try {
    await updateTestClockPoints({
      points: addPointsState.points,
    });
  } catch (error: any) {
    notify.showErrorNotification({
      description: error?.statusMessage ?? 'Failed to add points',
    });
  }
};

const onPointsKeypress = (e: KeyboardEvent) => {
  // Get the current value of the input
  const input = e.target as HTMLInputElement;
  const currentValue = input.value;

  // Allow only digits or a minus sign at the beginning
  if (!e.key.match(/^\d$/) && !(e.key === '-' && currentValue === '')) {
    e.preventDefault();
  }
};

const canMakeAdjustment = (type: PointTransactionType) => {
  return type === 'earned' || type === 'adjustment' || type === 'spent';
};
</script>

<template>
  <UModal
    v-model="isOpen"
    :ui="{
      width: 'w-full sm:max-w-[700px]',
      background: 'bg-transparent',
      shadow: 'shadow-none',
    }"
    @close="$emit('close')"
  >
    <div
      class="bg-beige-400 relative rounded-[20px] border-2 border-b-4 border-black px-4 py-2 text-center"
    >
      <div class="mx-auto my-6 flex max-w-[400px] flex-col">
        <h3
          class="font-roca text-tnNeutral-800 mb-4 text-2xl font-bold text-opacity-80"
        >
          Manage Points
        </h3>

        <UForm
          :schema="addPointsSchema"
          :state="addPointsState"
          class="flex items-start justify-center space-x-4"
          @submit="onSubmitAddPoints"
        >
          <UFormGroup
            v-slot="{ error }"
            class="max-w-[120px] flex-1"
            name="points"
            size="xl"
            :ui="{
              error: 'text-red-700 text-xs text-left',
            }"
          >
            <UInput
              v-model="addPointsState.points"
              type="number"
              :error="!!error"
              :min="0"
              :max="500"
              step="1"
              @keypress="onPointsKeypress"
            />
          </UFormGroup>
          <UButton :loading="isUpdating" type="submit" color="primary"
            >Add Points</UButton
          >
        </UForm>
      </div>

      <div class="my-4 flex items-center justify-center space-x-1">
        <span>
          <IconToken class="h-5 w-5" />
        </span>
        <span>{{ memberUser?.currentPoints }} points</span>
      </div>
      <hr class="border-black" />

      <UTable
        v-model:expand="expand"
        :loading="isLoading"
        :columns="columns"
        :rows="rows"
        class="max-h-[500px] overflow-y-auto"
        :ui="{
          wrapper: 'font-poppins',
          divide: 'divide-black',
          tbody: 'divide-black',
          td: {
            base: 'text-left',
          },
          tr: {
            expanded: 'bg-beige-500',
          },
          emptyState: {
            wrapper: 'py-6',
            icon: 'text-gray-900',
          },
        }"
      >
        <template #loading-state>
          <div class="flex flex-col items-center justify-center gap-3 py-6">
            <span class="text-sm italic">Loading...</span>
          </div>
        </template>
        <template #empty-state>
          <div class="flex flex-col items-center justify-center gap-3 py-6">
            <span class="text-sm italic">No points history.</span>
          </div>
        </template>
        <template #expand="{ row }">
          <div class="font-poppins py-4 text-left text-sm">
            <dl class="mx-auto grid w-max grid-cols-2 gap-4">
              <dt class="font-medium">Transaction ID:</dt>
              <dd class="text-right">{{ row.id }}</dd>

              <dt class="font-medium">Balance:</dt>
              <dd class="text-right">{{ row.balance }} pts</dd>

              <dt class="font-medium">Source ID:</dt>
              <dd class="text-right">{{ row.sourceId ?? '—' }}</dd>

              <dt class="font-medium">Source Type:</dt>
              <dd class="text-right">{{ row.sourceType ?? '—' }}</dd>

              <dt class="font-medium">Consumed By:</dt>
              <dd class="text-right">
                <ol v-if="row.consumedBy?.transactionIds?.length > 0">
                  <li
                    v-for="(item, idx) in row.consumedBy.transactionIds"
                    :key="item"
                  >
                    {{ item }} ({{ row.consumedBy.points[idx] }} pts)
                  </li>
                </ol>
                <span v-else>—</span>
              </dd>

              <dt class="font-medium">Consumed From:</dt>
              <dd class="text-right">
                <ol v-if="row.consumedFrom?.transactionIds?.length > 0">
                  <li
                    v-for="(item, idx) in row.consumedFrom.transactionIds"
                    :key="item"
                  >
                    {{ item }} ({{ row.consumedFrom.points[idx] }} pts)
                  </li>
                </ol>
                <span v-else>—</span>
              </dd>
            </dl>
            <div
              v-if="canMakeAdjustment(row.type)"
              class="mt-4 flex justify-center"
            >
              <UPopover>
                <button
                  class="text-center text-blue-600 underline"
                  @click="adjustedState.points = row.points * -1"
                >
                  Make Adjustment
                </button>

                <template #panel>
                  <div class="space-y-4 p-4 text-center">
                    <UForm
                      :schema="adjustedSchema"
                      :state="adjustedState"
                      class="max-w-[200px] space-y-4"
                      @submit="() => onSubmitAdjustment(row.id)"
                    >
                      <UFormGroup
                        v-slot="{ error }"
                        label="Adjust Points"
                        name="points"
                        size="xl"
                        :ui="{
                          error: 'text-red-700 text-xs text-left',
                        }"
                      >
                        <UInput
                          v-model="adjustedState.points"
                          type="number"
                          :error="!!error"
                          step="1"
                          @keypress="onPointsKeypress"
                        >
                          <template #leading>
                            <IconToken class="h-5 w-5" />
                          </template>
                        </UInput>
                      </UFormGroup>
                      <UButton
                        :loading="isAdjusting"
                        type="submit"
                        color="secondary"
                        >Make Adjustment</UButton
                      >
                    </UForm>
                  </div>
                </template>
              </UPopover>
            </div>
          </div>
        </template>
        <template #createdAtTimeMs-data="{ row }">
          <span>{{ dayjs(row.createdAtTimeMs).format('MM/DD/YYYY') }}</span>
        </template>
        <template #type-data="{ row }">
          <span>{{ row.type }}</span>
        </template>
        <template #points-data="{ row }">
          <span
            class="font-medium"
            :class="{
              'text-green-600': row.points > 0,
              'text-red-600': row.points < 0,
            }"
            >{{ row.points > 0 ? `+${row.points}` : row.points }}</span
          >
        </template>
        <template #remainingPoints-data="{ row }">
          <span
            class="font-medium"
            :class="{
              'text-green-600': row.remainingPoints > 0,
              'text-red-600': row.remainingPoints < 0,
            }"
            >{{ row.remainingPoints }}</span
          >
        </template>
        <template #pointsExpiresAtTimeMs-data="{ row }">
          <span
            :class="{
              'text-red-600': row.pointsHaveExpired,
            }"
            >{{
              row.pointsExpiresAtTimeMs
                ? dayjs(row.pointsExpiresAtTimeMs).format('MM/DD/YYYY')
                : '—'
            }}</span
          >
        </template>
      </UTable>
    </div>
  </UModal>
</template>
