import dayjs from 'dayjs';
import type { Cycle } from '@tn/shared';

export const getCycleByCycleNumber = (cycles: Cycle[], cycleNumber: number) => {
  return cycles.find((cycle) => cycle.cycleNumber === cycleNumber);
};

export const getCycleFromMsTimestamp = (
  cycles: Cycle[],
  msTimestamp: number
) => {
  return (
    cycles.find(
      (cycle) => cycle.startDate <= msTimestamp && cycle.endDate >= msTimestamp
    ) || null
  );
};

export interface CycleSelectorItem {
  label: string;
  value: string;
  year: string;
  click: () => void;
}

export function groupCyclesByYearForCycleSelector(
  cycles: Cycle[],
  updateFilter: (cycle: Cycle) => void
): Record<number, CycleSelectorItem[]> {
  // Group cycles by year extracted from cycle.startDate
  const grouped = cycles.reduce(
    (acc, cycle) => {
      const year = dayjs(cycle.startDate).year();
      if (!acc[year]) {
        acc[year] = [];
      }
      acc[year].push(cycle);
      return acc;
    },
    {} as Record<number, Cycle[]>
  );

  // Transform the grouped cycles into the desired output structure
  return Object.entries(grouped).reduce(
    (acc, [year, cycles]) => {
      acc[Number(year)] = cycles.map((cycle) => ({
        label: cycle.label,
        value: cycle.id,
        year,
        click: () => updateFilter(cycle),
      }));
      return acc;
    },
    {} as Record<number, CycleSelectorItem[]>
  );
}
