<script setup lang="ts">
import { setRemoteConfig } from '~/utils/fetchRemoteConfig';
import { VueQueryDevtools } from '@tanstack/vue-query-devtools';
import QuickSurveyContainer from '~/components/quick-survey/QuickSurveyContainer.vue';
import { type RemoteConfig, UserGroup } from '@tn/shared';
import { USER_GROUP_TO_MEMBER_SEGMENT } from '~/constants/userGroupToMemberSegment';
const remoteConfig = useState<RemoteConfig>('remote-config');
import EVENTS from '~/constants/events';
const { $trackEvent } = useNuxtApp();

const config = useRuntimeConfig();
const { refresh, uid, memberUser, userType, isRewardSystemUser, isBrand } =
  useUserState();
const auth = useFirebaseAuth();
const { checkImpersonation } = useImpersonation();

useTriggerOperations(uid.value);
let unsubscribe: (() => void) | undefined;

onMounted(() => {
  unsubscribe = auth?.onAuthStateChanged((user) => {
    checkImpersonation();

    if (user && uid.value !== user.uid) {
      refresh();
      setRemoteConfig();
    }
  });
});

onUnmounted(() => {
  unsubscribe?.();
});

watch(
  uid,
  (newValue) => {
    if (newValue) {
      const userTraits = {
        ...(userType.value && { user_type: userType.value }),
        ...(!isBrand.value && {
          member_segment: isRewardSystemUser.value
            ? USER_GROUP_TO_MEMBER_SEGMENT[UserGroup.RewardSystem]
            : USER_GROUP_TO_MEMBER_SEGMENT[UserGroup.ReoccurringTokens],
        }),
        ...(memberUser.value?.membership && {
          member_status: memberUser.value?.membership,
        }),
      };
      useIdentifyEvent(newValue, userTraits);
    }
    $trackEvent(EVENTS.APP_LOADED, {
      member_segment_event: isRewardSystemUser.value
        ? 'reward_system_only'
        : 'replenishing_tokens_only',
    });
  },
  { immediate: true }
);

const shouldShowDevTools = computed(
  () => config.public.appEnv !== 'production'
);
</script>

<template>
  <div id="tn-web-inbox"></div>
  <NuxtLayout>
    <NuxtPage />
    <UNotifications />
    <UModals />
  </NuxtLayout>
  <QuickSurveyContainer v-if="remoteConfig.quickSurveys.isEnabled && uid" />
  <TnDevTools v-if="shouldShowDevTools" />
  <VueQueryDevtools button-position="bottom-left" />
</template>

<style>
#unviewedBadge {
  display: none !important;
}
</style>
