import type { Variants } from '@amplitude/experiment-js-client';
import {
  Experiment,
  ExperimentClient,
  type ExperimentUser,
} from '@amplitude/experiment-js-client';
import RudderstackExposureTrackingProvider from '@/helpers/RudderstackExposureTrackingProvider';
import type { BrandUser, User } from '@tn/shared';
import { parseUserProperties } from '@/helpers/parseUserProperties';

type UserTraits =
  | Partial<User>
  | Partial<BrandUser>
  | { [key: string]: boolean | string | number };

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();
  const initialVariantsRef = useState<Variants>('variants');

  const initialVariants = initialVariantsRef.value ?? {};
  const experiment: ExperimentClient = Experiment.initialize(
    config.public.amplitudeClientDeploymentKey,
    {
      initialVariants: initialVariants,
      fetchOnStart: false,
      exposureTrackingProvider: RudderstackExposureTrackingProvider(),
      debug: process.env.VUE_APP_ENVIROMENT !== 'production',
    }
  );

  const fetchVariants = async (
    userId: string,
    userTraits: Partial<UserTraits>
  ) => {
    const parsedUserProperties = parseUserProperties(userTraits);

    const experimentUser: ExperimentUser = {
      user_id: userId,
      user_properties: { ...parsedUserProperties },
    };

    await experiment.fetch(experimentUser);
  };

  const getAllVariants = (): Variants => {
    return experiment.all();
  };

  const getVariant = (flagKey: string) => {
    return experiment.variant(flagKey);
  };

  const clearVariants = () => experiment.clear();

  return {
    provide: {
      fetchVariants,
      getAllVariants,
      getVariant,
      clearVariants,
    },
  };
});
