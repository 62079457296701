const MAX_RETRIES = 5;
const INITIAL_BACKOFF = 200;

export const executeWithRetry = async <T>(
  callback: () => T | Promise<T>,
  isExpected: (result: T) => boolean = (result) =>
    result !== undefined && result !== 0,
  maxRetries = MAX_RETRIES,
  initialBackoff = INITIAL_BACKOFF
): Promise<T | undefined> => {
  for (let retryCount = 0; retryCount <= maxRetries; retryCount++) {
    try {
      const result = await callback();

      if (isExpected(result)) {
        return result;
      }
    } catch (error) {
      console.error(`Error during retry ${retryCount + 1}:`, error);
    }

    if (retryCount < maxRetries) {
      const backoffTime = initialBackoff * 2 ** retryCount; // Exponential backoff
      await new Promise((resolve) => setTimeout(resolve, backoffTime));
    } else {
      return undefined;
    }
  }
};
