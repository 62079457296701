import type { RedeemPointsBody, UserPointHistoryResponse } from './types';

export async function getUserPointHistory(uid: string) {
  const { headers } = authenticatedRequestHeaders();

  return await $fetch<UserPointHistoryResponse>(
    `/api/users/${uid}/point-history`,
    {
      headers,
    }
  );
}

export async function redeemPoints(uid: string, body: RedeemPointsBody) {
  return await $fetch(`/api/users/${uid}/redeem`, {
    method: 'POST',
    body,
  });
}
