<script setup lang="ts">
import ManagePointsModal from '@/components/test-clock/ManagePointsModal.vue';
import IconToken from '@/assets/icons/token.svg?skipsvgo';
import { format, subDays, endOfMonth, addDays } from 'date-fns';
import cloneDeep from 'lodash/cloneDeep';
import {
  useTestClockBrands,
  brandUserRoles,
  subscriptionPlans,
  subscriptionStatuses,
  useResetTestClock,
  useUpdateTestClock,
} from '~/features/test-clock';
import { UserGroup } from '@tn/shared';

const {
  email,
  isCmUser,
  isPremium,
  isEmailVerified,
  hasCompletedOnboarding,
  isBrand,
  userGroup,
  canSeeRewardSystem,
  memberUser,
  brandUser,
  testClockTimeMs,
  isNewMember,
} = useUserState();
const notify = useNotifications();
const auth = useFirebaseAuth();

const isManagePointsModalOpen = ref(false);

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['close', 'update:modelValue']);

const tabs = [
  {
    key: 'member',
    label: 'Member',
    icon: 'i-heroicons-user',
  },
  {
    key: 'brand',
    label: 'Brand',
    icon: 'i-heroicons-building-office',
  },
];

const userGroups: { key: UserGroup; label: string }[] = [
  {
    key: UserGroup.ReoccurringTokens,
    label: 'Reoccurring Tokens',
  },
  {
    key: UserGroup.RewardSystem,
    label: 'Reward System',
  },
];

const { brands, suspense } = useTestClockBrands();
const { updateTestClock, isUpdating: isSaving } = useUpdateTestClock();
const { resetTestClock, isUpdating: isResetting } = useResetTestClock();

await suspense();

// Subscription Plan
const defaultSubscriptionPlan =
  subscriptionPlans.find(
    (plan) => plan.id === brandUser.value?.subscription?.plan
  ) || subscriptionPlans[1];
const selectedPlan = ref(defaultSubscriptionPlan);

// Subscription Status
const defaultSubscriptionStatus =
  subscriptionStatuses.find(
    (status) => status.id === brandUser.value?.subscription?.status
  ) || subscriptionStatuses[0];
const selectedSubscriptionStatus = ref(defaultSubscriptionStatus);

// Brand Info
const defaultBrand = isBrand
  ? brands.value?.find((brand) => brand.id === brandUser.value?.brand?.id)
  : brands.value?.[0];
const selectedBrand = ref(defaultBrand);
const selectedBrandUserRole = ref(brandUserRoles[0]);

const selectedUserGroup = ref<UserGroup[]>(
  userGroup?.value ? [...userGroup.value] : [userGroups[0].key]
);

const currentDate = computed(() =>
  testClockTimeMs.value ? new Date(testClockTimeMs.value) : new Date()
);

const DEFAULT_MEMBER_STATE = computed(() => [
  {
    key: 'isCmUser',
    label: 'CM User',
    active: isCmUser.value,
  },
  {
    key: 'isPremium',
    label: 'Premium',
    active: isPremium.value,
  },
  {
    key: 'hasCompletedOnboarding',
    label: 'Onboarding',
    active: hasCompletedOnboarding.value,
  },
  {
    key: 'isEmailVerified',
    label: 'Email Verified',
    active: isEmailVerified.value,
  },
  {
    key: 'isNewMember',
    label: 'New Member',
    active: isNewMember.value,
  },
]);

const activeTab = ref(isBrand.value ? 1 : 0);
const localDate = ref(currentDate.value);
const localMemberState = ref(cloneDeep(DEFAULT_MEMBER_STATE.value));

const isOpen = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit('update:modelValue', value);
  },
});

const getYesterday = () => subDays(currentDate.value, 1);

const getNextMonthStart = () => {
  const endOfCurrentMonth = endOfMonth(currentDate.value);
  return addDays(endOfCurrentMonth, 2);
};

watch(
  () => DEFAULT_MEMBER_STATE.value,
  (newState) => {
    localMemberState.value = cloneDeep(newState);
  },
  { deep: true }
);

const closeModal = () => {
  localDate.value = currentDate.value;
  emit('close');
};

const onResetState = async () => {
  try {
    await resetTestClock();
    notify.showSuccessNotification({
      description: 'User state reset successfully.',
    });
    localDate.value = new Date();
    emit('close');
  } catch (e) {
    console.error(e);
    notify.showErrorNotification({
      description: 'Something went wrong.',
    });
  }
};

const onSaveChanges = async () => {
  if (activeTab.value === 0 && selectedUserGroup.value.length === 0) {
    notify.showErrorNotification({
      description: 'Please select at least one user group.',
    });
    return;
  }

  const isCmUser = localMemberState.value.find(
    (item) => item.key === 'isCmUser'
  );
  const isPremium = localMemberState.value.find(
    (item) => item.key === 'isPremium'
  );
  const isEmailVerified = localMemberState.value.find(
    (item) => item.key === 'isEmailVerified'
  );
  const hasCompletedOnboarding = localMemberState.value.find(
    (item) => item.key === 'hasCompletedOnboarding'
  );
  const memberType = computed(() =>
    activeTab.value === 0 ? 'member' : 'brand'
  );
  const isNewMember = localMemberState.value.find(
    (item) => item.key === 'isNewMember'
  );

  await updateTestClock({
    testClockTimeMs: new Date(localDate.value).getTime(),
    memberType: memberType.value,
    isCmUser: !!isCmUser?.active,
    isPremium: !!isPremium?.active,
    isEmailVerified: !!isEmailVerified?.active,
    isNewMember: !!isNewMember?.active,
    hasCompletedOnboarding: !!hasCompletedOnboarding?.active,
    userGroup:
      selectedUserGroup.value?.length > 0
        ? selectedUserGroup.value
        : [userGroups[0].key],
    currentPoints: memberUser.value?.currentPoints ?? 0,
    ...(memberType.value === 'brand' && {
      brand: {
        id: selectedBrand.value?.id,
        name: selectedBrand.value?.name,
        role: selectedBrandUserRole.value?.id,
        subscriptionPlan: selectedPlan.value?.id,
        subscriptionStatus: selectedSubscriptionStatus.value?.id,
      },
    }),
  });

  // We need to reload the user to get the updated claims
  await auth?.currentUser?.reload();
};
</script>

<template>
  <UModal
    v-model="isOpen"
    :ui="{
      background: 'bg-transparent',
      shadow: 'shadow-none',
    }"
    @close="closeModal"
  >
    <ManagePointsModal
      v-model="isManagePointsModalOpen"
      @close="isManagePointsModalOpen = false"
    />
    <div
      class="font-poppins text-tnNeutral-800 rounded-[20px] border-2 border-b-4 border-black bg-white px-4 pt-10 text-center"
    >
      <h3 class="font-roca mb-2 text-2xl font-bold">Testing Playground</h3>
      <p class="text-tnNeutral-600 text-lg leading-tight">
        Update the current state for user:
        <strong class="text-tnNeutral-900 font-medium">{{ email }}</strong>
      </p>

      <div class="mx-auto mt-6 flex items-center justify-center space-x-2">
        <TnDatePicker
          v-model="localDate"
          :disabled-dates="[
            { start: new Date(0), end: getYesterday() },
            { start: getNextMonthStart(), end: null },
          ]"
        >
          <UButton
            color="secondary"
            icon="i-heroicons-calendar-days"
            :label="format(localDate, 'MMMM d, yyy')"
          />
        </TnDatePicker>

        <TnDatePicker v-model="localDate" mode="time">
          <UButton
            color="secondary"
            icon="i-heroicons-clock"
            :label="format(localDate, 'h:mm aa')"
          />
        </TnDatePicker>
      </div>

      <div class="mt-6">
        <UTabs v-model="activeTab" :items="tabs">
          <template #item="{ item }">
            <div v-if="item.key === 'member'">
              <div class="mx-auto mt-4 grid max-w-[350px] grid-cols-2">
                <UCheckbox
                  v-for="s in localMemberState"
                  :key="s.key"
                  v-model="s.active"
                  :name="s.key"
                  :label="s.label"
                />
              </div>
              <div class="mx-auto mt-4 max-w-[350px]">
                <UFormGroup label="User Group">
                  <USelectMenu
                    v-model="selectedUserGroup"
                    placeholder="User Group"
                    value-attribute="key"
                    option-attribute="label"
                    multiple
                    :options="userGroups"
                    :ui="{
                      wrapper: 'w-full md:w-full',
                      base: 'border-0',
                    }"
                  >
                    <template #label>
                      <span v-if="selectedUserGroup.length" class="truncate">{{
                        selectedUserGroup
                          .map(
                            (group) =>
                              userGroups.find((g) => g.key === group)?.label
                          )
                          .join(' + ')
                      }}</span>
                      <span v-else>Select one...</span>
                    </template>
                  </USelectMenu>
                </UFormGroup>
              </div>
              <div
                v-if="canSeeRewardSystem"
                class="mt-4 flex items-center justify-center space-x-1 text-sm"
              >
                <span>
                  <IconToken class="h-5 w-5" />
                </span>
                <div>
                  <span>{{ memberUser?.currentPoints ?? 0 }} points</span>
                  <button
                    v-if="testClockTimeMs"
                    class="ml-2 text-xs text-blue-500 hover:underline"
                    @click="isManagePointsModalOpen = true"
                  >
                    Manage
                  </button>
                </div>
              </div>
            </div>
            <div
              v-else-if="item.key === 'brand'"
              class="mx-auto mt-4 grid max-w-[420px] grid-cols-2 gap-4"
            >
              <UFormGroup label="Brand">
                <USelectMenu
                  v-model="selectedBrand"
                  searchable
                  searchable-placeholder="Search a brand..."
                  placeholder="Select a brand"
                  option-attribute="name"
                  :options="brands"
                  :ui="{
                    wrapper: 'w-full md:w-full',
                    base: 'border-0',
                  }"
                />
              </UFormGroup>
              <UFormGroup label="User Role">
                <USelectMenu
                  v-model="selectedBrandUserRole"
                  placeholder="Select a role"
                  :options="brandUserRoles"
                  :ui="{
                    wrapper: 'w-full md:w-full',
                    base: 'border-0',
                  }"
                />
              </UFormGroup>
              <UFormGroup label="Subscription Plan">
                <USelectMenu
                  v-model="selectedPlan"
                  placeholder="Select a plan"
                  :options="subscriptionPlans"
                  :ui="{
                    wrapper: 'w-full md:w-full',
                    base: 'border-0',
                  }"
                />
              </UFormGroup>
              <UFormGroup label="Subscription Status">
                <USelectMenu
                  v-model="selectedSubscriptionStatus"
                  placeholder="Select a subscription status"
                  :options="subscriptionStatuses"
                  :ui="{
                    wrapper: 'w-full md:w-full',
                    base: 'border-0',
                  }"
                />
              </UFormGroup>
            </div>
          </template>
        </UTabs>
      </div>

      <div
        class="mt-6 flex items-center justify-between space-x-2 border-t py-4"
      >
        <div>
          <UButton
            v-if="testClockTimeMs"
            variant="link"
            class="text-sm font-medium"
            :loading="isResetting"
            :disabled="isSaving"
            color="red"
            @click="onResetState"
          >
            Reset State
          </UButton>
        </div>
        <div class="flex items-center justify-end space-x-2">
          <UButton color="secondary" :disabled="isResetting" @click="closeModal"
            >Close</UButton
          >
          <UButton
            color="primary"
            :loading="isSaving"
            :disabled="isResetting"
            @click="onSaveChanges"
            >Save Changes</UButton
          >
        </div>
      </div>
    </div>
  </UModal>
</template>
