import { UserGroup } from '@tn/shared';
import { USER_GROUP_TO_MEMBER_SEGMENT } from '~/constants/userGroupToMemberSegment';

export const useClientVariantFetch = () => {
  const hasVariantBeenFetched = useState(
    'hasClientVariantBeenFetched',
    () => false
  );
  const { $fetchVariants } = useNuxtApp();

  const fetchVariantIfNeeded = async (uid: string, userGroup: UserGroup) => {
    // If we've already fetched variants, don't do it again
    if (hasVariantBeenFetched.value) {
      return;
    }

    const memberSegment = USER_GROUP_TO_MEMBER_SEGMENT[userGroup];

    await $fetchVariants(uid, {
      member_segment: memberSegment,
    });

    hasVariantBeenFetched.value = true;
  };

  const resetVariantFetchState = () => {
    hasVariantBeenFetched.value = false;
  };

  return {
    fetchVariantIfNeeded,
    resetVariantFetchState,
    hasVariantBeenFetched: computed(() => hasVariantBeenFetched.value),
  };
};
