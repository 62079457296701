import type { QueryClient } from '@tanstack/vue-query';
import type { UserDataResponse } from '@tn/shared';

export const invalidateUserPoints = async (
  queryClient: QueryClient,
  data: {
    uid: string;
    newBalance: number;
  }
) => {
  const previousData = queryClient.getQueryData<UserDataResponse>(['me']);
  if (previousData) {
    queryClient.setQueryData<UserDataResponse>(['me'], {
      ...previousData,
      memberUser: previousData.memberUser
        ? {
            ...previousData.memberUser,
            currentPoints: data.newBalance,
          }
        : null,
    });
  }

  await queryClient.invalidateQueries({
    queryKey: ['users', data.uid, 'points'],
    exact: true,
  });

  return data.newBalance;
};
