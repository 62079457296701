import { UserGroup, type RemoteConfig } from '@tn/shared';
import { useCurrentUserQuery } from '~/features/auth';

export const useUserState = () => {
  const { data, refetch } = useCurrentUserQuery();
  const remoteConfig = useState<RemoteConfig>('remote-config');

  const refresh = async () => {
    await refetch();

    if (data.value?.isBrand && !data?.value?.testClockTimeMs) {
      const brandSubscriptionStore = useBrandSubscriptionStore();
      await brandSubscriptionStore.fetchCurrentSubscription();
    }

    return data.value;
  };

  const isPointSystemEnabled = computed(
    () =>
      data?.value?.userGroup.includes(UserGroup.RewardSystem) &&
      remoteConfig?.value?.pointSystem?.isEnabled
  );

  const isRewardSystemUser = computed(() => {
    return (
      data.value?.memberUser?.userGroup?.includes(UserGroup.RewardSystem) ??
      false
    );
  });

  return {
    uid: computed(() => data.value?.uid),
    email: computed(() => data.value?.email),
    isEmailVerified: computed(() => data.value?.isEmailVerified),
    hasCompletedOnboarding: computed(() => data.value?.hasCompletedOnboarding),
    testClockTimeMs: computed(() => data.value?.testClockTimeMs),
    lastSeenRenewBannerMs: computed(() => data.value?.lastSeenRenewBannerMs),
    isCmUser: computed(() => data.value?.isCmUser),
    isPremium: computed(() => data.value?.isPremium),
    isNewMember: computed(() => data.value?.isNewMember),
    userType: computed(() => data.value?.userType),
    userGroup: computed(() => data.value?.userGroup),
    canSeeRewardSystem: computed(() => isPointSystemEnabled.value),
    canSeeReoccurringTokens: computed(() =>
      data.value?.userGroup.includes(UserGroup.ReoccurringTokens)
    ),
    membership: computed(() => data.value?.membership),
    isBrand: computed(() => data.value?.isBrand),
    memberUser: computed(() => data.value?.memberUser),
    brandUser: computed(() => data.value?.brandUser),
    isRewardSystemUser,
    refresh,
  };
};
