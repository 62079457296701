import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { updateTestClock, type UpdateTestClockBody } from '../api';

export const useUpdateTestClock = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (body: UpdateTestClockBody) => {
      return await updateTestClock(body);
    },
    onSuccess: async ({ uid }) => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['users', uid],
          exact: false,
        }),
        queryClient.invalidateQueries({
          queryKey: ['me'],
          exact: true,
        }),
        queryClient.invalidateQueries({
          queryKey: ['me'],
          exact: true,
        }),
        queryClient.invalidateQueries({
          queryKey: ['users', uid, 'points'],
          exact: true,
        }),
        queryClient.invalidateQueries({
          queryKey: ['activities'],
          exact: true,
        }),
      ]);
    },
  });

  return {
    updateTestClock: mutation.mutateAsync,
    isUpdating: mutation.isPending,
    error: mutation.error,
    isError: mutation.isError,
    isSuccess: mutation.isSuccess,
  };
};
