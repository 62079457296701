const getSessionReplayProperties = (
  rudderstackSessionId: number | null
): { [p: string]: string | boolean | null } => {
  if (!rudderstackSessionId) {
    return {};
  }

  const {
    $setSessionReplaySessionId,
    $getSessionReplaySessionId,
    $getSessionReplayProperties,
  } = useNuxtApp();

  if (!$getSessionReplaySessionId) {
    return {};
  }

  const amplitudeReplaySessionId = $getSessionReplaySessionId();

  if (
    rudderstackSessionId &&
    rudderstackSessionId !== amplitudeReplaySessionId
  ) {
    $setSessionReplaySessionId(rudderstackSessionId);
  }

  const amplitudeSessionReplayProperties = $getSessionReplayProperties();

  return amplitudeSessionReplayProperties;
};

export default getSessionReplayProperties;
