import { UserGroup, UserType } from '@tn/shared';

import { fetchCurrentUser } from '../api/requests';
import { useQuery } from '@tanstack/vue-query';

export const createCurrentUserQuery = () => ({
  queryKey: ['me'],
  queryFn: () => fetchCurrentUser(),
  placeholderData: {
    uid: null,
    email: null,
    isEmailVerified: false,
    hasCompletedOnboarding: false,
    testClockTimeMs: null,
    isCmUser: false,
    isPremium: false,
    isNewMember: false,
    userType: UserType.Member,
    userGroup: [UserGroup.ReoccurringTokens],
    isBrand: false,
    memberUser: null,
    membership: null,
    brandUser: null,
  },
  staleTime: 1000 * 60 * 60 * 1, // 1 hour
});

export function useCurrentUserQuery() {
  return useQuery(createCurrentUserQuery());
}
