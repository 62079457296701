import * as rudderanalytics from 'rudder-sdk-js';
import type { apiCallback, apiObject, apiOptions } from 'rudder-sdk-js';
import getSessionReplayProperties from '~/helpers/getSessionReplayProperties';
import { CookieProCategoryId } from '~/plugins/01.cookie-pro.client';
import type { RemoteConfig } from '@tn/shared';

export default defineNuxtPlugin(async () => {
  const config = useRuntimeConfig();
  const { $cookiePro, $sentry } = useNuxtApp();
  const { isImpersonating } = useImpersonation();

  const areEventsDisabled =
    config.public.rudderstackEventsDisabled.toString().toUpperCase() === 'TRUE';
  const remoteConfig = useState<RemoteConfig>('remote-config');
  const analyticsTrackingData = remoteConfig.value?.analyticsTrackingData;
  const ignoredTrackingEvents =
    analyticsTrackingData?.ignoredWebAppTrackEvents || [];

  const isPerformanceCookieEnabled = (): boolean => {
    return $cookiePro?.cookiesState[CookieProCategoryId.Performance] ?? false;
  };

  const initRudderstack = () => {
    try {
      rudderanalytics.load(
        config.public.rudderstackWriteKey,
        config.public.rudderstackDataPlane,
        {
          cookieConsentManager: {
            oneTrust: {
              enabled: true,
            },
          },
        }
      );
    } catch (error) {
      console.error('Error initializing rudderstack:', error);
      $sentry.captureException(error);
    }
  };

  $cookiePro.onConsentUpdated(() => {
    if (isPerformanceCookieEnabled()) {
      initRudderstack();
    }
  });

  rudderanalytics.ready(() => {
    console.info('Rudderstack Ready');
  });

  const deviceId = rudderanalytics.getAnonymousId();

  const getSessionId = () => {
    return rudderanalytics.getSessionId();
  };

  useState('deviceId', () => deviceId);

  const identifyEvent = (
    userId: string,
    traits: apiObject,
    options?: apiOptions
  ) => {
    if (rudderanalytics && isPerformanceCookieEnabled()) {
      console.info('Identify event | ', userId, traits, options || {});
      if (areEventsDisabled) {
        return;
      }

      rudderanalytics.identify(userId, traits, options);
    }
  };

  const trackEvent = (event: string, data?: Record<string, any>) => {
    if (isImpersonating.value || ignoredTrackingEvents.includes(event)) {
      return;
    }

    if (!$cookiePro?.cookiesState[CookieProCategoryId.Performance]) {
      return;
    }

    if (rudderanalytics && isPerformanceCookieEnabled()) {
      if (areEventsDisabled) {
        return;
      }

      const properties = getSessionReplayProperties(getSessionId());
      const computedData = properties ? { ...data, ...properties } : data;

      console.info('Track event | ', event, computedData || {});

      rudderanalytics.track(event, computedData);
    }
  };

  const resetEvent = () => {
    if (rudderanalytics && isPerformanceCookieEnabled()) {
      console.info('Reset event | ');
      rudderanalytics.reset(true);
    }
  };

  const pageEvent = ({
    name,
    category,
    properties,
    options,
    callback,
  }: {
    name?: string;
    category?: string;
    properties?: apiObject;
    options?: apiOptions;
    callback?: apiCallback;
  }) => {
    if (rudderanalytics && isPerformanceCookieEnabled()) {
      console.info('Page event');

      if (areEventsDisabled) {
        return;
      }
      rudderanalytics.page(category, name, properties, options, callback);
    }
  };

  return {
    provide: {
      trackEvent,
      identifyEvent,
      resetEvent,
      pageEvent,
      getSessionId,
    },
  };
});
