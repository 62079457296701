import type {
  UpdateTestClockBody,
  UpdateTestClockPointsBody,
  AdjustTestClockPointsBody,
} from './types';

export async function updateTestClock(body: UpdateTestClockBody) {
  return await $fetch('/api/test-clock', {
    method: 'POST',
    body,
  });
}

export async function resetTestClock() {
  return await $fetch('/api/test-clock', {
    method: 'DELETE',
  });
}

export async function getBrandsForTestClock() {
  return await $fetch('/api/brands/private');
}

export async function updateTestClockPoints(body: UpdateTestClockPointsBody) {
  return await $fetch('/api/test-clock/points', {
    method: 'POST',
    body,
  });
}

export async function adjustTestClockPoints(body: AdjustTestClockPointsBody) {
  return await $fetch('/api/test-clock/points/adjust', {
    method: 'POST',
    body,
  });
}
