import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { updateTestClockPoints, type UpdateTestClockPointsBody } from '../api';
import { invalidateUserPoints } from '../utils';

export const useManageTestClockPoints = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (body: UpdateTestClockPointsBody) => {
      return await updateTestClockPoints(body);
    },
    onSuccess: async (data) => {
      return await invalidateUserPoints(queryClient, {
        uid: data.uid,
        newBalance: data.newBalance,
      });
    },
  });

  return {
    updateTestClockPoints: mutation.mutateAsync,
    isUpdating: mutation.isPending,
    error: mutation.error,
    isError: mutation.isError,
    isSuccess: mutation.isSuccess,
  };
};
