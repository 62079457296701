import { useUserPointHistoryQuery } from '../queries';

export function useUserPointsHistory() {
  const { uid } = useUserState();
  const { data, isLoading, suspense, error } = useUserPointHistoryQuery(
    uid.value
  );
  const pointHistory = computed(() => data.value?.data || []);

  return {
    pointHistory,
    isLoading,
    error,
    suspense,
  };
}
