<script setup lang="ts">
import IconToken from '@/assets/icons/token.svg?skipsvgo';
import cloneDeep from 'lodash/cloneDeep';
import { format } from 'date-fns';
import { subscriptionPlans, subscriptionStatuses } from '~/features/test-clock';

const {
  uid,
  email,
  isCmUser,
  isPremium,
  isBrand,
  canSeeRewardSystem,
  memberUser,
  brandUser,
  isEmailVerified,
  hasCompletedOnboarding,
  testClockTimeMs,
  isNewMember,
} = useUserState();
const isModalOpen = ref(false);
const isLoggedIn = computed(() => Boolean(uid.value));

const DEFAULT_DATE = computed(() =>
  testClockTimeMs.value ? new Date(testClockTimeMs.value) : new Date()
);

const DEFAULT_USER_STATE = computed(() => [
  {
    key: 'isCmUser',
    label: 'CM User',
    active: isCmUser.value,
  },
  {
    key: 'isPremium',
    label: 'Premium',
    active: isPremium.value,
  },
  {
    key: 'hasCompletedOnboarding',
    label: 'Onboarding',
    active: hasCompletedOnboarding.value,
  },
  {
    key: 'isEmailVerified',
    label: 'Email Verified',
    active: isEmailVerified.value,
  },
  {
    key: 'isNewMember',
    label: 'New Member',
    active: isNewMember.value,
  },
]);

const currentDate = ref(DEFAULT_DATE.value);
const localDate = ref(DEFAULT_DATE.value);
const activeState = ref(cloneDeep(DEFAULT_USER_STATE.value));

watch(
  () => DEFAULT_DATE.value,
  (newDate) => {
    currentDate.value = newDate;
    localDate.value = newDate;
  }
);

watch(
  () => DEFAULT_USER_STATE.value,
  (newState) => {
    activeState.value = cloneDeep(newState);
  },
  { deep: true }
);

const shouldShow = computed(() => {
  return isLoggedIn.value;
});

const getLabel = (
  arr: {
    id: string;
    label: string;
  }[],
  key: string
) => {
  const state = arr.find((s) => s.id === key);
  return state ? state.label : null;
};

const subscriptionPlan = computed(() => {
  const plan = brandUser?.value?.subscription?.plan;

  if (!plan) {
    return '—';
  }

  return getLabel(subscriptionPlans, plan);
});

const subscriptionStatus = computed(() => {
  const status = brandUser?.value?.subscription?.status;

  if (!status) {
    return '—';
  }

  return getLabel(subscriptionStatuses, status);
});
</script>

<template>
  <div v-if="shouldShow" class="fixed bottom-0 right-0 z-20">
    <UPopover :popper="{ placement: 'top-end' }">
      <button
        class="font-poppins relative m-2 flex h-14 w-14 items-center justify-center rounded-full bg-[#042425] text-center text-xl font-bold text-[#F8A621]"
      >
        <span
          v-if="testClockTimeMs"
          class="font-poppins absolute right-[8px] top-[12px] inline-flex h-[8px] w-[8px] items-center justify-center rounded-full bg-red-400 text-xs font-medium text-white"
        ></span>
        TN
      </button>

      <TnTestClockModal v-model="isModalOpen" @close="isModalOpen = false" />

      <template #panel>
        <div class="font-poppins text-tnNeutral-800 w-[280px] text-sm">
          <div class="p-4">
            <p class="text-xs font-medium leading-none text-gray-400">
              Current Date
            </p>
            <h3 class="mb-1 flex items-center text-lg font-bold">
              <span>{{ format(currentDate, 'MMMM d, yyy') }}</span>
              <button @click="isModalOpen = true">
                <UIcon
                  name="i-heroicons-pencil"
                  class="ml-1 h-4 w-4 text-gray-500"
                />
              </button>
            </h3>
            <span
              class="block rounded-lg p-1 text-center text-xs font-medium"
              :class="{
                'bg-green-50 text-green-700': !testClockTimeMs,
                'bg-red-50 text-red-700': testClockTimeMs,
              }"
            >
              {{ testClockTimeMs ? 'Active Test State' : 'Current User State' }}
            </span>

            <div v-if="!isBrand">
              <div class="mt-4 grid grid-cols-2 gap-2">
                <div
                  v-for="s in activeState"
                  :key="s.key"
                  class="flex items-center space-x-1"
                >
                  <UIcon
                    v-if="s.active"
                    name="i-heroicons-check"
                    class="h-5 w-5 text-green-500"
                  />
                  <UIcon
                    v-else
                    name="i-heroicons-x-mark"
                    class="h-5 w-5 text-red-700"
                  />

                  <span>{{ s.label }}</span>
                </div>
              </div>

              <div
                v-if="canSeeRewardSystem"
                class="mt-4 flex items-center justify-center space-x-1"
              >
                <span>
                  <IconToken class="h-5 w-5" />
                </span>
                <span>{{ memberUser?.currentPoints }} points</span>
              </div>
            </div>

            <div v-else class="mt-4 space-y-3 text-center">
              <div class="grid grid-cols-2 gap-4">
                <div>
                  <span class="text-tnNeutral-400 block text-xs font-medium"
                    >Brand:</span
                  >
                  <span class="text-tnNeutral-700 block text-sm font-medium">{{
                    brandUser?.brand?.name
                  }}</span>
                </div>

                <div>
                  <span class="text-tnNeutral-400 block text-xs font-medium"
                    >Role:</span
                  >
                  <span class="text-tnNeutral-700 block text-sm font-medium"
                    >Admin</span
                  >
                </div>
              </div>
              <div class="grid grid-cols-2 gap-4">
                <div>
                  <span class="text-tnNeutral-400 block text-xs font-medium"
                    >Plan:</span
                  >
                  <span class="text-tnNeutral-700 block text-sm font-medium">
                    {{ subscriptionPlan }}</span
                  >
                </div>
                <div>
                  <span class="text-tnNeutral-400 block text-xs font-medium"
                    >Subscription:</span
                  >
                  <span
                    class="block text-sm font-medium"
                    :class="{
                      'text-green-700':
                        brandUser?.subscription?.status === 'active',
                      'text-orange-500':
                        brandUser?.subscription?.status === 'grace',
                      'text-red-700':
                        brandUser?.subscription?.status === 'canceled',
                      'text-tnNeutral-700':
                        brandUser?.subscription?.status === 'none',
                    }"
                  >
                    {{ subscriptionStatus }}
                  </span>
                </div>
              </div>
            </div>

            <div class="mt-4 flex items-center justify-center">
              <UButton color="primary" @click="isModalOpen = true"
                >Update State</UButton
              >
            </div>
          </div>
          <p
            class="border-t border-dashed border-gray-300 bg-gray-50 p-2 text-center text-xs"
          >
            <span
              class="mb-1 inline-block rounded-full px-2 py-0.5 font-medium text-white"
              :class="{
                'bg-blue-600': isBrand,
                'bg-green-600': !isBrand,
              }"
            >
              {{ isBrand ? 'Brand' : 'Member' }}
            </span>
            <br /><span class="font-medium">{{ email }}</span
            ><br />
          </p>
        </div>
      </template>
    </UPopover>
  </div>
</template>
